<template>
  <div class="tender-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-select
            v-model="searchModel.companyGuid"
            placeholder="所属公司"
            clearable>
            <el-option
              v-for="company in companys"
              :key="company.companyGuid"
              :label="company.companyName"
              :value="company.companyGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchModel.projectGuid"
            placeholder="所属项目"
            clearable>
            <el-option
              v-for="project in projects"
              :key="project.projectGuid"
              :label="project.projectName"
              :value="project.projectGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            type="text"
            v-model="searchModel.tenderName"
            placeholder="标段名称"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button type="primary" @click="handleAddClick">添加</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <el-table
      :data="tenderList"
      border
      header-cell-class-name="bg-info text-light"
      >
      <el-table-column fixed="left" type="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column fixed="left" prop="tenderName" width="50" header-align="center" align="center" label="名称"></el-table-column>
      <el-table-column fixed="left" prop="tenderFullName" width="100" header-align="center" align="center" label="全名称"></el-table-column>
      <el-table-column fixed="left" prop="engineeringType" width="80" header-align="center" align="center" label="工程类型"></el-table-column>
      <el-table-column fixed="left" prop="constructionUnit" width="280" header-align="center" align="center" label="施工单位"></el-table-column>
      <el-table-column prop="startMileage" width="90" header-align="center" align="center" label="起始桩号"></el-table-column>
      <el-table-column prop="endMileage" width="90" header-align="center" align="center" label="终止桩号"></el-table-column>
      <el-table-column prop="tenderLength" width="80" header-align="center" align="center" label="长度"></el-table-column>
      <el-table-column prop="startDate" width="100" header-align="center" align="center" label="开工日期"></el-table-column>
      <el-table-column prop="completeDate" width="100" header-align="center" align="center" label="竣工日期"></el-table-column>
      <el-table-column prop="winningAmount" width="100" header-align="center" align="center" label="中标金额"></el-table-column>
      <el-table-column prop="listAmount" width="100" header-align="center" align="center" label="清单金额"></el-table-column>
      <el-table-column prop="contractAmount" width="100" header-align="center" align="center" label="合同金额"></el-table-column>
      <el-table-column prop="superviseUnit" width="280" header-align="center" align="center" label="监理单位"></el-table-column>
      <el-table-column prop="testUnit" width="280" header-align="center" align="center" label="试验检测单位"></el-table-column>
      <el-table-column prop="ownerUnit" width="280" header-align="center" align="center" label="业主单位"></el-table-column>
      <el-table-column prop="reportTemplateName" width="150" header-align="center" align="center" label="报表模板"></el-table-column>
      <el-table-column :formatter="yesOrNoFormatter" prop="tenderType" width="80" header-align="center" align="center" label="合同段"></el-table-column>
      <el-table-column prop="staffName" width="80" header-align="center" align="center" label="创建者"></el-table-column>
      <el-table-column prop="sortId" width="50" header-align="center" align="center" label="排序"></el-table-column>
      <el-table-column :formatter="timeFormatter" width="160" header-align="center" align="center" prop="createTime" label="创建时间"></el-table-column>
      <el-table-column fixed="right" width="90" header-align="center" align="center" label="操作">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="light" content="编辑" :open-delay="500" placement="top">
            <i @click="handleEditClick(scope.row)" class="text-info far fa-edit mr-2 operation-button"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <my-dialog ref="formDialog"
      :title="formDialogTitle"
      size="xl"
      ok-text="保存"
      cancel-text="取消"
      @ok-click="handleOkClick">
      <tender-form
        ref="tenderForm"
        :tenderModel="tenderModel">
      </tender-form>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import TenderForm from '@/views/TenderManage/TenderForm'
import TenderModel from '@/model/TenderModel'
import tenderService from '@/services/tenderService'
import PageOperation from '@/mixins/PageOperation'
import GetProject from '@/mixins/GetProject'

export default {
  name: 'TenderIndex',
  mixins: [
    GetProject,
    PageOperation
  ],
  components: {
    MyDialog,
    MySearchBar,
    TenderForm
  },
  watch: {
    companyGuid (newVal) {
      this.$set(this.searchModel, 'projectGuid', '')
      this.projects = []
      newVal && this._getProjects(newVal)
    }
  },
  computed: {
    companyGuid () {
      return this.searchModel.companyGuid
    }
  },
  data () {
    return {
      tenderList: [],
      tenderModel: new TenderModel(),
      formDialogTitle: '',
      searchModel: {
        companyGuid: '',
        projectGuid: '',
        tenderName: ''
      }
    }
  },
  methods: {
    yesOrNoFormatter (row, column) {
      if (!row.empty) {
        return row[column.property] === 1 ? '是' : '否'
      }
      return '无效'
    },
    handleSearchClick () {
      this._getTenderList()
    },
    handleAddClick () {
      this.formDialogTitle = '添加标段信息'
      this.tenderModel = new TenderModel()
      this.$refs.formDialog.open()
    },
    handleEditClick (row) {
      this.formDialogTitle = '编辑标段信息'
      this.tenderModel = new TenderModel(row)
      this.$refs.formDialog.open()
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _tenderModel = new TenderModel(row)
          tenderService.delete(_tenderModel.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getTenderList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleOkClick () {
      this.$refs.tenderForm.validate()(valid => {
        if (valid) {
          if (!this.tenderModel.resourceId) {
            this._save()
          } else {
            this._edit()
          }
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    _getTenderList () {
      tenderService.list({}, this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.tenderList = res.data.data
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _save () {
      tenderService.add(this.tenderModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getTenderList()
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '添加失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _edit () {
      tenderService.edit(this.tenderModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getTenderList()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    }
  },
  created () {
    this._getTenderList()
  }
}
</script>

<style scoped lang="scss">
::v-deep ::-webkit-scrollbar {
  display: block;
}
::v-deep ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::v-deep ::-webkit-scrollbar-track {
  border-radius: 15px;
  width: 10px;
}
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(200, 200, 200, 0.3);
}
::v-deep ::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
</style>
